<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="应用名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['name', { rules: [{ required: true, message: '应用名称不能为空' }] }]"
          placeholder="请输入应用名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="版本号" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="number" v-decorator="['version', { rules: [{ required: true, message: '版本号不能为空' }] }]" placeholder="请输入版本号"></a-input>
      </a-form-item>
      <a-form-item label="强制升级" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-decorator="['forceUpdate', {initialValue:'true', rules: [{ required: true, message: '请选择强制升级' }] }]"
                       defaultValue="true">
          <a-radio value="true">
            是
          </a-radio>
          <a-radio value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="升级说明" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" v-decorator="['summary']" placeholder="请输入升级说明"></a-input>
      </a-form-item>
      <a-form-item label="上传文件" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef"
          @getFileData="getFileData"
          :multiSelection="false"
          type="edit"
          :items="fileUrls"
        ></qiniu-file-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { upgradeFormVO } from './common/upgradeFormVO'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'AppUpgradeForm',
    mixins: [entityCURDCallback],
    components: { QiniuFileUpload },
    data () {
      return {
        initFormItem: upgradeFormVO(),
        fileUrls: []
      }
    },
    computed: {},
    mounted () {
    },
    methods: {
      // 添加
      submitAdd () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.appUpgrade.create,
              data: this.formItem,
              loading: 'spinShow',
              success: (data) => {
                //清空表单数据
                this.formItem = upgradeFormVO()
                this.fileUrls = []
                this.form.resetFields()
                this.$emit('addSuccess', data.body)
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      },
      // setFields (values) {
      //   Object.assign(this.formItem, values)
      //   this.form.setFieldsValue({
      //     name: this.formItem.name,
      //     version: this.formItem.version,
      //     forceUpdate: this.formItem.forceUpdate + '',
      //     summary: this.formItem.summary
      //   })
      // },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.version = values.version
        this.formItem.forceUpdate = values.forceUpdate
        this.formItem.summary = values.summary
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.formItem.fileUrl = data[0].src
      }
    }
  }
</script>

<style scoped></style>
