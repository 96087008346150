<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">升级管理</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button class="m-l-12" icon="plus" type="primary" @click="handleAdd">创建
        </a-button>
      </div>
    </div>
    <!-- ↓↓↓表格↓↓↓ -->
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="应用名称">
            <a-input v-model="searchParams.name"
                     @pressEnter="search" style="width: 250px;" placeholder="应用名称"/>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading">
        <span slot="serial" slot-scope="text, record, index">{{
          searchParams.number * searchParams.size + index + 1
        }}</span>
        <template slot="forceUpdate" slot-scope="text, record">
          <div v-if="record.forceUpdate">是</div>
          <div v-else>否</div>
        </template>
        <template slot="state" slot-scope="text, record">
          <a-tag v-if="record.state===0" color="green">正常</a-tag>
          <a-tag v-else color="red">删除</a-tag>
        </template>
        <span slot="operation" slot-scope="text, record">
          <template>
            <a-button
              type="primary"
              @click="downFile(record.fileUrl)"
              shape="circle"
              icon="download"
              size="small"></a-button>
            <a-divider type="vertical"/>
            <a-button
              type="danger"
              @click="handleDelete(record.id, '')"
              shape="circle"
              icon="delete"
              size="small"></a-button>
          </template>
        </span>
      </a-table>
    </a-card>
    <a-modal
      title="升级添加"
      v-model="addModal"
      :maskClosable="false"
      okText="添加"
      cancelText="取消"
      :confirmLoading="confirmButtonStatus"
      @ok="handleAddOk">
      <upgrade-form type="add" ref="addRef"
                    @addSuccess="addSuccess"
                    @addError="addError"></upgrade-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import entityCRUD from '../../../views/common/mixins/entityCRUD'
  import PageLayout from '../../../components/page/PageLayout'
  import UpgradeForm from './Form'

  export default {
    name: 'UserList',
    components: { PageLayout, UpgradeForm },
    mixins: [entityCRUD],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.appUpgrade,
        searchParams: {
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated',
          name: '',
          version: '',
          summary: ''
        },
        initColumns: [
          {
            title: '序号',
            align: 'center',
            fixed: 'left',
            scopedSlots: { customRender: 'serial' },
            width: 70
          },
          {
            title: '应用名称',
            dataIndex: 'name',
            align: 'center',
            key: 'name',
            width: 120
          },
          {
            title: '版本号',
            dataIndex: 'version',
            align: 'center',
            key: 'version',
            width: 150
          },
          {
            title: '是否强制升级',
            align: 'center',
            width: 140,
            scopedSlots: { customRender: 'forceUpdate' }
          },
          {
            title: '升级说明',
            dataIndex: 'summary',
            align: 'center',
            width: 150,
            key: 'summary'
          },
          {
            title: '升级时间',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            align: 'center',
            width: 150
          },
          {
            title: '下载地址',
            dataIndex: 'fileUrl',
            align: 'center',
            width: 100,
            key: 'fileUrl'
          },
          {
            title: '状态',
            align: 'center',
            width: 80,
            scopedSlots: { customRender: 'state' }
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 188,
            align: 'center',
            fixed: 'right',
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    created () {
    },
    methods: {
      downFile (fileUrl) {
        window.open(fileUrl)
      },
      search () {
        this.$http(this, {
          url: this.searchUrl,
          noTips: true,
          data: this.searchParams,
          loading: 'tableLoading',
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
          }
        })
      },
      /*添加*/
      handleAdd () {
        this.addModal = true
      },
      resetForm () {
        this.searchParams = {
          q: '',
          oIdStr: undefined,
          jId: undefined,
          number: '0',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        }
        this.search()
      }
    }
  }
</script>
